import React from 'react';
import './BearList.scss';

// Bears
import crystal from "../../Images/bearCrystal.png"
import crystalOutline from "../../Images/bearCrystalOutline.png"
import crystalDing from "../../Audio/ding_crystal.mp3"

import scout from "../../Images/bearScout.png"
import scoutOutline from "../../Images/bearScoutOutline.png"
import scoutDing from "../../Audio/ding_scout.mp3"

import melody from "../../Images/bearMelody.png"
import melodyOutline from "../../Images/bearMelodyOutline.png"
import melodyDing from "../../Audio/ding_melody.mp3"

import boomer from "../../Images/bearBoomer.png"
import boomerOutline from "../../Images/bearBoomerOutline.png"
import boomerDing from "../../Audio/ding_boomer.mp3"

import Bear from "../Bear/Bear";

class BearList extends React.Component {
    

    render() {
        //Do not display the bears on the front or back cover
        if (this.props.page === 0 || this.props.page === 17) {
            return (
                ''
            )
        }

        return (
            <div className={'bearList'} aria-live="polite">
                <Bear 
                    bear={crystal} 
                    bearOutline={crystalOutline} 
                    ding={crystalDing}
                    playSound={this.props.playSound} 
                    name={"crystal"} 
                    book={this.props.book.icon_id} 
                    page={this.props.page}
                    talkingBear={this.props.talkingBear}
                    narrationOn={this.props.narrationOn}/>
                <Bear 
                    bear={melody} 
                    bearOutline={melodyOutline}
                    ding={melodyDing}
                    playSound={this.props.playSound} 
                    name={"melody"} 
                    book={this.props.book.icon_id} 
                    page={this.props.page}
                    talkingBear={this.props.talkingBear}
                    narrationOn={this.props.narrationOn}/>
                <Bear 
                    bear={scout} 
                    bearOutline={scoutOutline}
                    ding={scoutDing}
                    playSound={this.props.playSound} 
                    name={"scout"} 
                    book={this.props.book.icon_id} 
                    page={this.props.page}
                    talkingBear={this.props.talkingBear}
                    narrationOn={this.props.narrationOn}/>
                <Bear 
                    bear={boomer} 
                    bearOutline={boomerOutline}
                    ding={boomerDing}
                    playSound={this.props.playSound} 
                    name={"boomer"} 
                    book={this.props.book.icon_id} 
                    page={this.props.page}
                    talkingBear={this.props.talkingBear}
                    narrationOn={this.props.narrationOn}/>
            </div>
        )
    };
}

export default BearList;
