import React from 'react';
import ModalConfirmation from "./ModalConfirmation";
import ModalOptions from "./ModalOptions";
import ModalVideo from "./ModalVideo";

import './Modal.scss';

class ModalDefault extends React.Component{

    render() {
        if (this.props.modalMode === 'options-menu'){
            return(
                <ModalOptions
                    narrationOn={this.props.narrationOn}
                    closeModal={this.props.closeModal}
                    captionsOn={this.props.captionsOn}
                    handleCaptionsClick={this.props.handleCaptionsClick}
                    handleNarrationClick={this.props.handleNarrationClick}/>
            )
        } else if (this.props.modalMode === 'book-confirmation'){
            return(
                <ModalConfirmation
                    selectedBook={this.props.selectedBook} 
                    confirmBook={this.props.confirmBook} 
                    closeModal={this.props.closeModal}
                    playSound={this.playSound}/>
            )
        } else if (this.props.modalMode === "video-intro" || this.props.modalMode === "video-reading" || this.props.modalMode === "video-end"){
            return(
                <ModalVideo
                    selectedBook={this.props.selectedBook}
                    closeModal={this.props.closeModal}
                    playSound={this.props.playSound}
                    soundOn={this.props.soundOn}
                    captionsOn={this.props.captionsOn}
                    videoTarget={this.props.modalMode}/>
            )
        }
        
    }
}

export default ModalDefault;
