import React from 'react';
import './BookIcon.scss';

class BookIcon extends React.Component {

    iconPlacement = {
        left: this.props.bookData.splashScreenX + "%",
        top: this.props.bookData.splashScreenY + "%",
        width: this.props.bookData.spashScreenWidth,
        height: this.props.bookData.spashScreenHeight
    }

    render() {
        return (
            <button className={"bookIconContainer"} id={this.props.id} onClick={() => this.props.handleBookClick(this.props.bookData.icon_id)} style={this.iconPlacement} aria-label="Launch confirmation modal and examine book">
                <img src={this.props.cover} alt={'Book with red cover.'}/>
            </button>
        )
    };
}

export default BookIcon;