import React from 'react';
import {Link} from "react-router-dom";

import './Modal.scss';

import btnClose from '../../Images/Icons/close_btn.png';
import coverGeneric from '../../Images/GenericBook/cover-generic.png';

class ModalConfirmation extends React.Component{

    render() {
        return(
            <div className={'modal modal__confirm'} aria-live="polite">
                <Link to="/book" onClick={this.props.confirmBook} aria-label="Open this book and play intro video in modal">
                    <img src={coverGeneric} alt="Red book cover with paw print logo." id={"start-book-"+this.props.selectedBook}></img>
                </Link>
                <button className={"btn-close"} onClick={this.props.closeModal} aria-label="Close">
                    <img src={btnClose} alt='Close Icon.'></img>
                </button>
            </div>
        )
    }
}

export default ModalConfirmation;
