import React from 'react';
import './TitleScreen.scss';

import {Link} from 'react-router-dom';

//Image Assets
import LogoMain from '../../Images/Logos/BHB_Logo_English.png';
import LogoBlock from '../../Images/Logos/logo_block.png';
import StartBtn from '../../Images/Icons/start_btn.png';

import throbber from "../../Video/throbber_scout_btn.gif";
import btnShadow from "../../Images/Icons/btn_shadow.png";

class TitleScreen extends React.Component {
    constructor(props) {
        super(props)

        this.state = {
            contentLoaded: false,
            playReady: false
        }
    }

    componentDidMount(){
        this.props.changeBg(4)

        this.loadHumansTxt();
        //Would use prefetch but it is not supported by Safari
        this.preloadVideo();
    }

    loadHumansTxt = () => {
        const meta = document.createElement("link");
        meta.href = "humans.txt";
        meta.rel = "author";
        document.head.appendChild(meta);
    }

    preloadVideo = () => {
        window.addEventListener("load", ()=>{
            //once the main content has loaded, we render a hidden modal to begin caching the video
            this.setState({contentLoaded: true}, () => {
                addListenerToVideo();
            })
        })

        const addListenerToVideo = () => {
            //once the video has loaded enough to play, switch from the loading gif to the play game link
            document.getElementById('video-preload').addEventListener("canplay", () => {
                enablePlayButton()
            });
        }

        const enablePlayButton = () => {
            this.setState({playReady: true});
        }
    }

    render() {
        let icon;
        if(this.state.playReady){
            icon = <>
                <Link to='/find' onClick={() => this.props.playVideo('video-intro')} className={'btn-start'} aria-label="Go to main screen and play intro video in modal">
                    <img src={StartBtn} alt="Play game arrow icon."/>
                </Link>
            </>
        } else {
            icon = <>
                <div className={'btn-start'}>
                    <img src={btnShadow} className={'btn-shadow'} alt=""/>
                    <img src={throbber} alt="Play button: video loading, not ready."/>
                </div>
            </> 
        }

        let video = <>
            <video className={'hidden'} controls="controls" autoPlay muted id={"video-preload"} type="video/mp4">
                <source src={'https://bhb-video.s3.amazonaws.com/bhb_intro.mp4'} type="video/mp4"/>
            </video>
        </>

        return (
            <div className={"titleScreen"}>
                <div className={"title-main"}>
                    <img src={LogoMain} alt="Book Hungry Bears paw print logo with a bear's face as each toe."></img>
                    <div>
                        <h1>Share a Story</h1>
                    </div>
                </div>

                {icon}

                <div className={"logos"}>
                    <img src={LogoBlock} alt="Logos of contributors: Canada Media Fund, TVO kids, groupe media TFO, Breakthrough Entertainment, Pukeko Pictures, Shaw Rocket Fund, and Stitch Media, written by Jamie Piekarz."/>
                </div>

                {(this.state.contentLoaded ? video : '')}
            </div>
        )
    };
}

export default TitleScreen;
