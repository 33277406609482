import React from 'react';
import {Link} from 'react-router-dom';

import './Controls.scss';

import btnSoundOff from "../../Images/Icons/soundOff_btn.png";
import btnSoundOn from "../../Images/Icons/soundOn_btn.png";
import btnSettings from "../../Images/Icons/btn-settings.png";
import btnHome from "../../Images/Icons/home_btn.png";
import btnSpeech from "../../Images/Icons/speech_btn.png"

class Controls extends React.Component {

    handleClickSound = () => {
        this.props.toggleSound();
    }

    handleClickSettings = () => {
        this.props.handleMenuClick();
    }

    handleClickHome = () => {
        this.props.handleHomeClick();
    }

    handleClickReplay = () => {
        this.props.handleReplayClick();
    }

    render() {
        let controlsClass = 'controls';
        //Hide the controls on the title screen
        if (this.props.backgroundIndex === 4) {
            controlsClass = controlsClass + ' hidden'
        }
        //Additional class added when on the home screen. Mainly used to hide home link
        if (this.props.backgroundIndex === 5) {
            controlsClass = controlsClass + ' controls-home'
        }

        return (
            <div className={controlsClass} id='controls' aria-live="polite">
                <div>
                    <button className={'openControls'} id={'controls-1'} onClick={this.handleClickSettings} aria-label="Open options menu modal">
                        <img className={"btn-settings"} src={btnSettings} alt={"Gear Icon."}/>
                    </button>
                    <button className={'btn btn-narrate'} id={'controls-2'} onClick={this.handleClickReplay} aria-label="Replay Narration">
                        <img src={btnSpeech} alt="Replay Icon."/>
                    </button>
                </div>
                <div className={'controls-right'}>
                    <button className={"btn btn-sound"} id={'controls-3'} onClick={this.handleClickSound} aria-label={this.props.soundOn === true ? "Sound on/off toggle. Currently On." : "Sound on/off toggle. Currently Off."}>
                        <img src={this.props.soundOn ? btnSoundOn : btnSoundOff} alt={this.props.soundOn === true ? "Sound on/off icon. Currently On." : "Sound on/off icon. Currently Off."}/>
                    </button>
                    <Link to='/find' id={'controls-4'} onClick={this.props.stopSound} aria-label="Return to main screen">
                        <img src={btnHome} alt={"Home Icon."}/>
                    </Link>
                </div>
            </div>
        )
    }
}

export default Controls;