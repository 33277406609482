import React from 'react';
import './PawCover.scss';

import coverImage from '../../Images/GenericBook/cover-generic.png';

class PawCover extends React.Component{

    constructor(props)
    {
        super(props);

        this.state = {
            hidden: false,
            activated: false
        };
    }

    //Background index 6 is the dirt floor, which only appears the first time a book is opened.
    //If this isn't the first time seeing the cover page, do not display this component 
    //Still play author credit but without bears saying woah because book is already revealed
    componentDidMount (){
        if(this.props.backgroundIndex !== 6){
            this.setState({hidden: true})
            let soundId = 'b'+this.props.book+'_author';
            this.props.playSound('cover', soundId);
        }
    }

    //Apply animation to fade this image
    //Then play full sound effect
    handleClick = () => {
        this.setState({activated: true})
        let soundId = 'b'+this.props.book+'_author_woah';
        this.props.playSound('cover', soundId);
        //hide component when animation is done(mainly for screen readers)
        setTimeout(() => {
            this.setState({hidden: true});
            this.props.togglePageDisplay();
        }, 2500)
    }

    render() {
        let classNameCover = 'cover-paw'
        if (this.state.activated === true){
            classNameCover = 'cover-paw fadeout'
        }
        if (this.state.hidden === true){
            classNameCover = 'hidden'
        }

        return(
            <div className={classNameCover}>
                <button id={'paw-btn'} onClick={this.handleClick} aria-label="Start the story">
                    <img src={coverImage} alt={"A red book cover with a pressable paw print."}></img>
                </button>
            </div>
        )
    }
}

export default PawCover;