import React from 'react';
import './InteractiveBookScreen.scss';
import NavigationBook from '../Book/NavigationBook/NavigationBook'
import BearList from "../BearList/BearList";
import Book from "../Book/Book";

class InteractiveBookScreen extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            page: 0,
            backCover: false
        };

        // Function bindings
        this.nextPage = this.nextPage.bind(this);
        this.previousPage = this.previousPage.bind(this);
    }

    componentDidMount (){
        this.props.setCmfDataLayer();
        this.runCmfPageLoad();
        document.title = 'Read ' + this.props.book.title;
    }

    runCmfPageLoad = () => {
        //Do not try to run the pageBottom script if the main script is not loaded
        if(document.getElementById("cmfScript") === null){
            return
        }
        //Delete the script if it already exists.
        if(document.getElementById("cmfPageLoad") !== null){
            const target = document.getElementById("cmfPageLoad");
            target.parentNode.removeChild(target);
        }

        //Load and execute the script
        const cmfRenderScript = document.createElement("script");
        cmfRenderScript.id = "cmfPageLoad";
        cmfRenderScript.async = true;
        cmfRenderScript.defer = true;
        cmfRenderScript.text = `_satellite.track('page load');`;
        document.body.appendChild(cmfRenderScript);
    }

    checkStart = () => {
        if (this.state.page === 0) {
            this.setState({backCover: false});
            return true;
        } else {
            return false;
        }
    }

    checkFirstPage = () => {
        if (this.state.page === 1) {
            this.setState({backCover: false});
            return true;
        } else {
            return false;
        }
    }

    checkBackCover = () => {
        let bookLength = this.props.book.pages.length - 1;
        if (this.state.page === bookLength) {
            this.setState({backCover: true});
            return true;
        } else {
            this.setState({backCover: false});
            return false;
        }
    }

    //ARCHITECTURE WORKAROUND (see also controls.scss for same keyword)
    //This is bad
    //The replayNarration function was not part of the original scope and was not accounted for in the architecture, which is why this escape hatch is needed.
    //The Controls component, and therefore Game component, need to know the page number in order to hide the replay button
    //This function is run whenever this component updates to add the page number to the class of game
    //As far as I know there is nothing that will cause game.js to rerender and lose this class at an important time.
    updateGameClass = (page)=> {
        let game = document.getElementById('game');
        game.classList.remove(game.classList[1]);
        if(page){
            game.classList.add('p'+page);
        }
    }

    componentDidUpdate (prevProps, prevState){
        if(this.state.page !== prevState.page){
            this.updateGameClass(this.state.page);
        }
    }

    // If on the front cover of the book, increase page number by one.
    // If in the main content of the book, increase by two pages. 
    // After flipping two pages, check if we are at the back cover to make sure the correct button is rendered for navigation
    nextPage() {

        //Initialize playSound function to make sure its inner functions are defined
        this.props.playSound('init', 'init');
        //Stop all bear and narrator sounds when turning the page
        this.props.playSound.stopSound();

        if (this.checkStart() === true) {
            this.props.changeBg(0);
            this.setState({page: this.state.page+1});
        } else if (this.checkBackCover() === false) {
            this.setState({page: this.state.page+2}, ()=> this.checkBackCover());
        }

        this.runCmfPageLoad();
    }

    // If on the first page of the book, decrease page number by one so that we land on the front cover, page 0.
    // If in the main content of the book, decrease by two pages. 
    // Also sets state.backCover to false to make sure the correct button is rendered for navigation
    previousPage() {

        //Initialize playSound function to make sure its inner functions are defined
        this.props.playSound('init', 'init');
        //Stop all bear and narrator sounds when turning the page
        this.props.playSound.stopSound();

        if (this.checkFirstPage() === true) {
            this.setState({
                page: this.state.page-1,
                backCover: false
            });
        } else if (this.checkStart() === false){
            this.setState({
                page: this.state.page-2,
                backCover: false
            });
        }

        this.runCmfPageLoad();
    }

    componentWillUnmount() {
        this.updateGameClass();
        this.props.bookUnloaded();
    }

    render() {
        return (
            <div className={"interactive-book-screen"} aria-live="polite">
                <NavigationBook 
                    page={this.state.page} 
                    pages={this.props.pages} 
                    book={this.props.book} 
                    nextPage={this.nextPage}
                    previousPage={this.previousPage}
                    closeBook={this.props.closeBook}
                    backCover={this.state.backCover}/>
                <Book 
                    page={this.state.page} 
                    pageImages={this.props.pageImages} 
                    ref={this.props.pageRef} 
                    book={this.props.book}
                    backgroundIndex={this.props.backgroundIndex}
                    changeBg={this.props.changeBg}
                    playSound={this.props.playSound}
                    soundIsPlaying={this.props.soundIsPlaying}
                    soundOn={this.props.soundOn}
                    currentSoundType={this.props.currentSoundType}
                    narrationOn={this.props.narrationOn}/>
                <BearList
                    book={this.props.book}  
                    page={this.state.page}
                    playSound={this.props.playSound}
                    talkingBear={this.props.talkingBear}
                    narrationOn={this.props.narrationOn}/>
            </div>
        )
    }
}

export default InteractiveBookScreen;