import React from 'react';
import './SplashScreen.scss';

// Book Component
import BookIcon from "../BookIcon/BookIcon";

//Image Assets
import searchingMelody from "../../Images/Searching/searching_melody.png";
import cover0 from "../../Images/Searching/cover-0.png";
import cover1 from "../../Images/Searching/cover-1.png";
import cover2 from "../../Images/Searching/cover-2.png";
import cover3 from "../../Images/Searching/cover-3.png";

class SplashScreen extends React.Component {

    componentDidMount(){
        this.props.changeBg(5);
        document.title = 'Find a Book';
        this.loadCmfPageBottom();
    }

    loadCmfPageBottom = () => {
        //Do not try to run the pageBottom script if the main script is not loaded
        if(document.getElementById("cmfScript") === null){
            return
        }
        //Delete the script if it already exists.
        if(document.getElementById("cmfPageBottom") !== null){
            const target = document.getElementById("cmfPageBottom");
            target.parentNode.removeChild(target);
        }

        //Load and execute the script
        const cmfPageLoadScript = document.createElement("script");
        cmfPageLoadScript.id = "cmfPageBottom";
        cmfPageLoadScript.async = true;
        cmfPageLoadScript.defer = true;
        cmfPageLoadScript.text = `_satellite.pageBottom();`;
        document.body.appendChild(cmfPageLoadScript);
    }

    render() {
        return (
            <div className={"splashScreen"} aria-live="polite">
                <img src={searchingMelody} className={"searching-melody"} alt=""></img>
                <div className={"iconWrapper"}>
                    {/*TODO: Use a reducer function to stop this redundancy*/}
                    <BookIcon handleBookClick={this.props.handleBookClick} bookData={this.props.bookData[0]} cover={cover0} id={'book1'}/>
                    <BookIcon handleBookClick={this.props.handleBookClick} bookData={this.props.bookData[1]} cover={cover1} id={'book2'}/>
                    <BookIcon handleBookClick={this.props.handleBookClick} bookData={this.props.bookData[2]} cover={cover2} id={'book3'}/>
                    <BookIcon handleBookClick={this.props.handleBookClick} bookData={this.props.bookData[3]} cover={cover3} id={'book4'}/>
                </div>
            </div>
        )
    };
}

export default SplashScreen;
