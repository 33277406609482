import React from 'react';
import styled from "styled-components"

// Stylize the background so it uses the prop data to load a specific background
const BackgroundStyled = styled.div`

    // Setup the background image
    background-image: url(${props => props.backgroundImage});
       
    // Setup the background properties
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    
    // Make the background viewable
    position: absolute;
    top: 0;
    height: 100%;
    width: 100%;
    
    // Background should go behind the content
    z-index: -1;
`;

class Background extends React.Component {

    render() {
        return (
            <BackgroundStyled backgroundImage={this.props.backgroundImage}/>
        )
    }
}

export default Background;