import React from 'react';
import './Captions.scss';

class Captions extends React.Component {

    render() {
        return (
            <div className={this.props.shown ? 'captions' : 'captions hidden'}>
                <p>{this.props.speaker}</p>
                <p>{this.props.text}</p>
            </div>
        )
    };
}

export default Captions;