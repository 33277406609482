import './Hitbox.scss';
import React from "react";
class Hitbox extends React.Component {

    constructor(props) {
        super(props)

        this.state = {
            animationActive: false
        }
    }

    handleClick = () => {
        let soundId = this.props.data.id;
        this.props.playSound('clickable', soundId);
        this.setState({animationActive: true}, () => {
            setTimeout(()=>{
                this.setState({animationActive: false})
            }, 2500)
        })
    }

    divStyle = {
        height: this.props.data.height + "%",
        width: this.props.data.width + "%",
        left: this.props.data.xPos + "%",
        top: this.props.data.yPos + "%",
    }

    render() {
        const id = this.props.data.id;
        let className = '';
        let classNamePh = '';
        let classNameTf = '';

        //This applies the animated class to all images in this clickable element while the state decrees
        //When the timeout in handleClick expires, the state changes and animations are removed
        if (this.state.animationActive) {
            //This applies to the most simple clickables, such as those with the 'fade' animation type
            className = 'animated ' + this.props.data.animationType;
            //Two frame animations require the page image to have an empty space where the animated images appear and disappear
            //Since there is an empty space in the background, a placeholder is needed to fill the space
            //When the element becomes animated, the placeholder alternates between being visible and invisible in time with the second frame
            //See 'twoFrame' and 'twoFrame_ph' animations
            classNamePh = 'clickable-placeholder animated ' + this.props.data.placeholderAnimationType;
            //Some two frame animations have an outline around the element
            //In such cases, the first frame cannot be used as a placeholder as it is already outlined
            //We then use three frames, with the non-outlined placeholder dissappearing for the whole duration of the animation
            //See book2.json, b2_p8_clickable
            classNameTf = 'transparent animated ' + this.props.data.thirdFrameAnimationType;
        } else {
            className = '';
            classNamePh = 'clickable-placeholder';
            classNameTf = 'transparent';
            if(this.props.data.placeholderDefaultTransparent){
                classNamePh += ' transparent';
            }
        }

        //We need to dynamically update the description of any image that changes:
        //Initial description:
        let alt = this.props.data.altText;
        //Changed description during animation:
        if (this.state.animationActive && this.props.data.placeholder) {
            alt = this.props.data.animatedAlt;
        }

        let thirdFrame = ''
        if (this.props.data.thirdFrame){
            thirdFrame = <>
                <img src = {this.props.imageTf} className={classNameTf} style={this.divStyle} aria-hidden='true' alt=''></img>
            </>
        }

        if (this.props.data.placeholder) {
            return (
                <div>
                    {thirdFrame}
                    <img src={this.props.imagePh} className={classNamePh} style={this.divStyle} aria-hidden='true' alt=''></img>
                    <button id={id} className={'box'} style={this.divStyle} onClick={this.handleClick} aria-label={"Play interaction: "+this.props.data.altText} aria-live='polite'>
                        <img src={this.props.image} alt={alt} className={className}></img>
                    </button>
                </div>
            );
        } else {
            return (
                <button id={id} className={'box'} style={this.divStyle} onClick={this.handleClick}>
                    <img src={this.props.image} alt={alt} className={className}></img>
                </button>
            );
        }

        
    }
}

export default Hitbox;