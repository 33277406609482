import React from 'react';
import './App.css';
import Game from "./Components/Game/Game";

function App() {

    // If user is on chrome we need to calculate the height of the window minus height of the address bar so that the game will not be hidden behind it
    // Detect user OS.
    const ua = navigator.userAgent;

    const setVh = () => {
        // First we get the viewport height 
        let windowHeight = window.innerHeight;
        if (/Chrome/i.test(ua)){
            //modify it if in chrome
            windowHeight -= 56;
        }
        //multiply it by 1% to get a value for a vh unit
        let vh = windowHeight * 0.01;
        // Then we set the value in the --vh custom property to the root of the document
        document.documentElement.style.setProperty('--vh', `${vh}px`);
    }

    setVh();

    // Repeat whenever window is resized
    window.addEventListener('resize', () => {
        setVh();
    });

    return (
        <Game/>
    );
}

export default App;