import React from 'react';

import './Modal.scss';

//Caption Files
import introCaptions from"../../Video/bhb_intro.vtt";
import readingCaptions from"../../Video/bhb_reading.vtt";
import endCaptions from"../../Video/bhb_end.vtt";

class ModalVideo extends React.Component{

    componentDidMount(){
        const closeModal =(e)=> {
            //ORIGIN: game.js
            //closing the modal will begin the loop of reminder audio if the user is on the book hunting screen 
            this.props.closeModal()

            //if the user has confirmed a book and watched the intro video, play the prompt to touch the book instead
            if (this.props.videoTarget === 'video-reading'){
                this.props.playSound('prompt2', '');
            }
        }
        document.getElementById('video-main').addEventListener('ended',closeModal,false);
        let vid = document.getElementById('video-main');
        vid.volume = 0.8;
    }

    render() {
        let video;
        let captions;

        if(this.props.videoTarget === 'video-intro'){
            video = 'https://bhb-video.s3.amazonaws.com/bhb_intro.mp4';
            captions = introCaptions;
        } else if (this.props.videoTarget === 'video-reading'){
            video = 'https://bhb-video.s3.amazonaws.com/bhb_reading.mp4';
            captions = readingCaptions;
        } else if (this.props.videoTarget === 'video-end'){
            video = 'https://bhb-video.s3.amazonaws.com/bhb_end.mp4';
            captions = endCaptions;
        }
        
        return(
            <div className={'modal modal__video'} aria-live="polite">
                <video controls="controls" autoPlay="autoplay" muted={this.props.soundOn === true ? '' : 'muted'} id={"video-main"} tabIndex="0" type="video/mp4">
                    <source src={video} type="video/mp4"/>
                    <track label="English" kind="subtitles" srcLang="en" src={captions} default={this.props.captionsOn ? true : false}></track>
                    Your browser does not support the video tag.
                </video>
            </div>
        )
    }
}

export default ModalVideo;
