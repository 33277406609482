import React from 'react';
import './Game.scss';
import './Buttons.scss';
import './Accessibility.scss';

//Custom Components
import TitleScreen from "../TitleScreen/TitleScreen";
import SplashScreen from "../SplashScreen/SplashScreen";
import InteractiveBookScreen from "../InteractiveBookScreen/InteractiveBookScreen";
import ModalDefault from "../Modal/ModalDefault";
import Controls from "../Controls/Controls";
import Captions from "../Captions/Captions";

//Libraries
import Modal from 'react-modal';
import {Switch, Route, HashRouter} from "react-router-dom";
import {Howler, Howl} from "howler";

// Book Data
import book0 from "../../Books/Data/book0.json";
import book1 from "../../Books/Data/book1.json";
import book2 from "../../Books/Data/book2.json";
import book3 from "../../Books/Data/book3.json";
import { returnStatement } from '@babel/types';

//Background component and background images
import Background from "../Background/Background"
import backgroundTitle from "../../Images/Backgrounds/bg_title.png";
import backgroundHunting from "../../Images/Backgrounds/bg_hunting.png";
import backgroundGround from "../../Images/Backgrounds/bg_ground.png";
import background0 from "../../Images/Backgrounds/bg_book-0.png";

//Generic Book Covers
import coverBack from "../../Images/GenericBook/page-17.png";

//Audio assets and subtitles
import searchMusic from "../../Audio/bookhunt.mp3";
import melodyReminder from "../../Audio/reminder.mp3";
import captionsGeneral from "../../Audio/captions.json";
import pawPrompt from "../../Audio/paw_prompt.mp3";
import pawPrompt2 from "../../Audio/paw_prompt_2.mp3";
import touchPrompt from '../../Audio/touch_prompt.mp3';

// This will import our audio (Code from Alex Leitch)
function importAll(r) {
    let sounds = {};
    r.keys().map((item, index) => { sounds[item.replace('./', '')] = r(item); });
    return sounds;
}

class Game extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            //Sound related items
            soundOn: true,
            soundIsPlaying: false,
            narrationOn: true,
            //Variable passed down to book component. Whenever the user interrupts a sound, this will change
            //This is used to stop the karaoke animation
            currentSoundType: '',
            //Used to light up the currently speaking bear
            talkingBear: '',

            //Reminder timeout/interval storage
            melodyReminderInitial: null,
            melodyReminderInterval: null,

            //Captions
            captionsOn: false,
            captionBoxShown: false,
            captionSpeaker: '',
            captionText: '',
            captionTimer: null,
            captionDataGeneral: captionsGeneral,

            //Image assets
            backgroundImages: [
                background0,
                background0,
                background0,
                background0,
                backgroundTitle,
                backgroundHunting,
                backgroundGround
            ],

            // The index of which background to render
            // There is functionality to use a different background for each book but only #0 is used at the moment
            // 0-3 are for the respective books
            // 4 is the title screen
            // 5 is the book hunting screen
            // 6 is the plain ground when first opening each book
            backgroundIndex: 4,

            // Our selected book state
            selectedBook: null,

            //Modal Controls
            modalIsOpen: false,
            modalMode: '',

            videoPlaying: false,

            bookData: [
                book0,
                book1,
                book2,
                book3
            ]
        };

        this.onBookUnloaded = this.onBookUnloaded.bind(this);

        //react-modal built in functions
        this.openModal = this.openModal.bind(this);
        this.closeModal = this.closeModal.bind(this);

        this.pageRef = React.createRef();
    }

    loadStitchAnalytics = () => {
        //Delete the script if it already exists.
        if(document.getElementById("gtmSrcLoad") !== null){
            const target = document.getElementById("gtmSrcLoad");
            target.parentNode.removeChild(target);
        }

        //Load and execute the script
        const gtmSrcScript = document.createElement("script");
        gtmSrcScript.id = "gtmSrcLoad";
        gtmSrcScript.async = true;
        gtmSrcScript.src = `https://www.googletagmanager.com/gtag/js?id=UA-1375413-19`;
        document.head.appendChild(gtmSrcScript);

        //Load and execute the data layer
        const gtmDataScript = document.createElement("script");
        gtmDataScript.id = "gtmLayerLoad";
        gtmDataScript.text = `  
            window.dataLayer = window.dataLayer || [];
            function gtag(){dataLayer.push(arguments);}
            gtag('js', new Date());
            gtag('config', 'UA-1375413-19');
        `;
        document.head.appendChild(gtmDataScript);
    }

    //CMF analytics functions
    loadCmfAnalytics = () => {
        //Create and set script 
        const script = document.createElement("script");
        script.src = "https://assets.adobedtm.com/41b7a8e674452e42c4a9f83d28f8193e334610be/satelliteLib-7d92ac1c6840397bad9d6186e49b1298bbf5fe24.js";
        script.id = "cmfScript";
        script.async = true;
        script.defer = true;
        document.head.appendChild(script);

        this.setCmfDataLayer();
    }

    setCmfDataLayer = () =>{
        //Do not try to run the pageBottom script if the main script is not loaded
        if(document.getElementById("cmfScript") === null){
            return
        }
        //Delete the script if it already exists
        if(document.getElementById("cmfDataLayer") !== null){
            const target = document.getElementById("cmfDataLayer");
            target.parentNode.removeChild(target);
        }

        //Set script with new data
        const script2 = document.createElement("script");
        script2.id = "cmfDataLayer";
        script2.async = true;
        script2.defer = true;
        script2.text = `
        var digitalData = digitalData || {};
        digitalData = {
            page: {
                applicationId: "1718.11112.302632",
                siteName: "Book Hungry Bears",
                mediaName: "Book Hungry Bears Adventure I GameApp",
                pageUrl: window.location.href
            },
        };`;
        document.head.appendChild(script2);
    }
    //End of CMF analytics functions

    componentDidMount() {
        //load CMF analytics script
        this.loadCmfAnalytics();
        this.loadStitchAnalytics();

        //Set parent for react-modal
        Modal.setAppElement('#game');

        //the reminder is normally launched when the video closes so this plays it when the book hunting screen is refreshed
        if(!this.state.modalIsOpen && this.state.backgroundIndex === 5){
            this.playSound('reminder', '');
            this.playSound('music', '');
        }
    }

    componentDidUpdate(prevProps, prevState){
        //when we return to the book hunting screen using the home link from a book, play the reminder
        if(prevState.backgroundIndex !== 5 && this.state.backgroundIndex === 5){
            if(!this.state.modalIsOpen){
                this.playSound('reminder', '');
                this.playSound('music', '');
            }
        }
    }

    openBook = () => {
        let bookId = this.state.selectedBook;
        let sounds = undefined;
        let captions = undefined;
        let pageImports = undefined;
        let pages = [];
        let bookToOpen = this.state.bookData[bookId];

        //arguments of require.context must be static so we use a switch statement instead of feeding in a variable path
        switch (bookId){
            case '0':
                sounds = importAll(require.context("../../Books/Audio/Book0", false, /\.(m4a|mp3|ogg)$/));
                captions = importAll(require.context("../../Books/Captions/Book0", false, /\.(json)$/));
                pageImports = importAll(require.context("../../Books/Pages/Book0", false, /\.(png)$/));
                break;
            case '1':
                sounds = importAll(require.context("../../Books/Audio/Book1", false, /\.(m4a|mp3|ogg)$/));
                captions = importAll(require.context("../../Books/Captions/Book1", false, /\.(json)$/));
                pageImports = importAll(require.context("../../Books/Pages/Book1", false, /\.(png)$/));
                break;
            case '2':
                sounds = importAll(require.context("../../Books/Audio/Book2", false, /\.(m4a|mp3|ogg)$/));
                captions = importAll(require.context("../../Books/Captions/Book2", false, /\.(json)$/));
                pageImports = importAll(require.context("../../Books/Pages/Book2", false, /\.(png)$/));
                break;
            case '3':
                sounds = importAll(require.context("../../Books/Audio/Book3", false, /\.(m4a|mp3|ogg)$/));
                captions = importAll(require.context("../../Books/Captions/Book3", false, /\.(json)$/));
                pageImports = importAll(require.context("../../Books/Pages/Book3", false, /\.(png)$/));
                break;    
        }

        //Leftover code from Adrian
        //TODO: figure out why this is here
        // Loop through all the pages
        for (let i = 0; i < bookToOpen.pages.length; i++) {

            // Loop through all the page imports
            for (let j = 0; j < Object.keys(pageImports).length; j++) {

                // If this static page contains this page, we will push this to our pages array (So we can render the correct page illustrations according to the json)
                if(Object.values(pageImports)[j].includes(bookToOpen.pages[i].image)) {
                    pages.push(Object.values(pageImports)[j]);
                    break;
                }
            }
        }
        pages.push(coverBack);
        this.setState({sounds: sounds, captions: captions["captions.json"], pageImages: pages});
    }

    // Callback function when the book has loaded
    // Also called when the title screen and book hunting screens mount for the first time
    changeBg = (bgIndex) => {
        // Fill in the background from the book contents
        this.setState({backgroundIndex: bgIndex});
    }

    // Callback when the book unmounts
    onBookUnloaded() {
        this.setState({backgroundIndex: 4});
    }

    //react-modal functionality
    openModal() {
        this.setState({modalIsOpen: true});
    }
    
    closeModal() {
        this.setState({modalIsOpen: false});

        if(this.state.backgroundIndex === 5){
            //when any modal (video/options/confirmation) closes on the book hunting screen, begin playing the background music and reminder VO
            this.playSound('reminder', '');

            //the options modal does not stop the music loop when it opens, so only play music when the other two modal close
            if(this.state.modalMode !== 'options-menu'){
                this.playSound('music', '');
            }
        }

        this.fixTalkbackFocus(this.state.modalMode);
    }

    fixTalkbackFocus = (context) => {
        //Google talkback does not work by default, the view gets stuck on the top level webview unless the user taps an element manually or switches into controls mode.
        //This function manually focuses the elements we want Talkback to see to give them visibility.
        //Then it returns focus to where it should be after the modal closes
        //---The video modals always involve a change in context, so the focus is placed on the first element in the page (the open options menu button)
        //---The options menu returns focus to the button that opened the modal (also the open options menu button)
        if(context === 'video-intro' || context === 'video-end'){
            document.getElementById('controls-3').focus();
            document.getElementById('book1').focus();
            document.getElementById('book2').focus();
            document.getElementById('book3').focus();
            document.getElementById('book4').focus();
            document.getElementById('controls-1').focus();
        } else if(context === 'video-reading'){
            document.getElementById('paw-btn').focus();
            document.getElementById('controls-4').focus();
            document.getElementById('controls-3').focus();
            document.getElementById('controls-2').focus();
            document.getElementById('controls-1').focus();
        } else if(context === 'options-menu') {
            if(this.state.backgroundIndex === 6 || this.state.backgroundIndex === 0){
                document.getElementById('nav-left').focus();
                document.getElementById('nav-right').focus();
                document.getElementById('controls-1').focus();
            }
        } else {
            return;
        }
    }

    //Open the controls modal
    handleMenuClick = () => {
        this.setState({
            modalMode: 'options-menu'
        }, () => this.openModal())
        //Stop the loop of reminder audio
        //Loop will resume automatically when closeModal runs
        clearInterval(this.state.melodyReminderInterval);
        clearTimeout(this.state.melodyReminderInitial);
    }

    //This function needs to access the page number from the state of InteractiveBookScreen, therefore we use a ref.
    //The replayNarration function was not part of the original scope and was not accounted for in the architecture, which is why this escape hatch is needed.
    handleReplayClick = () => {
        if(this.pageRef.current !== null){
            this.pageRef.replayNarration();
        }
    }
    
    //When clicking on a book on the main splash screen, bring up the confirmation modal for the selected book.
    handleBookClick = (bookId) => {
        this.setState({
            selectedBook:bookId,
            modalMode: 'book-confirmation'
        }, () => this.openModal())

        //Stop the loop of reminder audio
        //Loop will resume automatically when closeModal runs
        clearInterval(this.state.melodyReminderInterval);
        clearTimeout(this.state.melodyReminderInitial);

        this.playSound.stopSound()
        this.playSound('prompt')
    }

    toggleNarration = () => {
        this.setState({
            narrationOn:!this.state.narrationOn
        })
    }

    toggleCaptions = () => {
        this.setState({
            captionsOn:!this.state.captionsOn
        })
    }

    //This is passed as a property to any component that needs to play sound
    //Skip to SOUND1.0 for main logic
    playSound = (mode, soundId) => {

        const stopShowingCaption = () => {
            this.setState({captionBoxShown: false})
        }

        //Create a timer to hide the captions and store it in state so that it can be deleted if needed
        const setTimeToHideCaptions = (path) => {
            let timeToHideCaptions = setTimeout(()=>{stopShowingCaption()}, path.time);
            this.setState({captionTimer: timeToHideCaptions});
        }

        //This function is called whenever a sound is played by playDouble or playSingle
        const showCaption = (path) => {
            //Make sure the captions don't hide early based on the hide time of another caption
            clearTimeout(this.state.captionTimer);

            if (this.state.captionsOn === false || path === undefined){
                return;
            }

            //Reveal the caption component and fille in the correct info
            this.setState({
                captionBoxShown: true,
                captionSpeaker: path.speaker,
                captionText: path.text
            })

            //Stop showing the captions component after the time specified in the json.
            setTimeToHideCaptions(path);
        }

        //Stop all active sounds and update state to allow more sounds to play
        const stopSound = () =>{
            this.setState({
                soundIsPlaying: false,
                captionBoxShown: false,
                talkingBear: ''
            });
            for (let i in Howler._howls){
                Howler._howls[i].stop();
            }
        }

        //Function binding
        this.playSound.stopSound = stopSound;

        //Create the file paths used by the logic below (see SOUND1.0)
        //If no matching file is found, end function
        const createPath = (target) =>{
            let pathArr = {
                audPath: '',
                subPath: ''
            }
            for(var key in this.state.sounds){
                if (key.split('.')[0] === target){
                    pathArr.audPath = this.state.sounds[key];
                }
            }
            for(var key2 in this.state.captions){
                if (key2.split('.')[0] === target){
                    pathArr.subPath = this.state.captions[key2];
                }
            }
            if(pathArr.audPath === ''){
                pathArr.audPath = false;
                pathArr.subPath = false;
            }

            return pathArr;
        }

        const playSingle = (audPath, subPath) => {
            const sound = new Howl({
                src: [audPath],
                //This second argument is passed because new sounds do not seem to automatically adopt the global volume from Howler
                //Same for playDouble
                volume: Howler.volume()
            });
            
            sound.play();
            showCaption(subPath);
            
            //Normally this variable is used to disable new sounds while another is playing but it is currently not used
            this.setState({soundIsPlaying: true})
    
            sound.on('end', () => {
                this.setState({soundIsPlaying: false})
                this.setState({talkingBear: ''})
            });
        }

        //Same as playSingle but plays part b of the sound after the first part
        const playDouble = (audPath, subs, audPath_b, subs_b) => {
            const sound = new Howl({
                src: [audPath],
                volume: Howler.volume()
            });

            const sound2 = new Howl({
                src: [audPath_b],
                volume: Howler.volume()
            });
    
            sound.play();
            showCaption(subs);
    
            this.setState({soundIsPlaying: true})

            sound.on('end', () => {
                sound2.play();
                showCaption(subs_b);
            }); 

            sound2.on('end', () => {
                this.setState({soundIsPlaying: false})
            });
        }

        //The book hunting screen has a music track which loops continuously
        const playMusic = () => {
            const music = new Howl({
                src: [searchMusic],
                loop: true,
            });
            
            music.play();
        }

        //Melody reminds the user to find a book on a regular basis
        const playReminder = () => {
            const melody = new Howl({
                src: [melodyReminder],
                volume: Howler.volume()
            });

            //Initial play of the sound
            const melodyReminderInitial = setTimeout(() => {
                melody.play();
                showCaption(captionsGeneral['melody_reminder']);
            }, 2000)
            this.setState({melodyReminderInitial: melodyReminderInitial});

            //Repeat audio reminder by melody every 16 seconds.
            //16 seconds was chosen because it plays after the end of the intro video.
            const melodyReminderInterval = setInterval( ()=>{
                melody.play()
                showCaption(captionsGeneral['melody_reminder']);
            }, 16000);
            //Store the interval so that it can be cleared when the book is confirmed
            this.setState({melodyReminderInterval: melodyReminderInterval});
        }

        const updateTalkingBear = (soundId) => {
            var re = /(?:_p\d+_)(\w*)$/
            if(soundId.match(re)===null){
                return;
            }
            let bearName = soundId.match(re)[1];
            this.setState({talkingBear: bearName});
        }

        //This function is called before any actual sounds are played to create the inner functions
        //In this case, exit now
        if (mode === 'init'){
            return
        }

        //Checks if a sound is currently active before playing a new sound
        if (this.state.soundIsPlaying){
            //This check is currently disabled to let users interrupt active sounds by pressing buttons
            //return
        }

        //SOUND1.0 Main function

        //Preload path data which will be used by all if cases
        let pathData = createPath(soundId);
        let audPath1 = pathData.audPath;
        let subPath1 = pathData.subPath;

        //main logic
        if (mode === 'bear') {
            if(audPath1 === false){
                return
            }

            stopSound();

            updateTalkingBear(soundId);
            this.setState({currentSoundType: mode});

            playSingle(audPath1, subPath1);
        } else if (mode === 'narrator'){
            pathData = createPath(soundId+'_b');
            let audPath2 = pathData.audPath;
            let subPath2 = pathData.subPath;
            
            //Pages come in sets of two, of which none, one or both may have narration. Play files as appropriate
            if (audPath1 === false && audPath2 === false){
                return
            } else if (audPath1 === false && audPath2 !== false){
                stopSound();
                this.setState({currentSoundType: mode});
                playSingle(audPath2, subPath2);
            } else if (audPath2 === false && audPath1 !== false){
                stopSound();
                this.setState({currentSoundType: mode});
                playSingle(audPath1, subPath1);
            } else{
                stopSound();
                this.setState({currentSoundType: mode});
                playDouble(audPath1, subPath1, audPath2, subPath2);
            }
        } else if (mode === 'clickable'){
            if(audPath1 === false){
                return
            }
            stopSound();
            this.setState({currentSoundType: mode});
            playSingle(audPath1, subPath1);
        } else if (mode === 'music'){
            playMusic();
        } else if (mode === 'reminder'){
            playReminder();
        } else if (mode === 'prompt'){
            stopSound();
            this.setState({currentSoundType: mode});
            playSingle(pawPrompt, captionsGeneral['paw_prompt']);
        } else if (mode === 'prompt2'){
            stopSound();
            this.setState({currentSoundType: mode});
            playSingle(pawPrompt2, captionsGeneral['paw_prompt_2']);
        } else if (mode === 'touchPrompt'){
            stopSound();
            this.setState({currentSoundType: mode});
            playSingle(touchPrompt, captionsGeneral['touch_prompt']);
        } else if (mode === 'cover'){
            stopSound();
            this.setState({currentSoundType: mode});
            playSingle(audPath1, subPath1);
        } else if (mode === 'otherNonInterrupting'){
            this.setState({currentSoundType: mode});
            playSingle(soundId);
        }
    }

    toggleSound = () => {
        //Toggle the global sound in state, then when that is finished, pass the data on to the global Howler.
        //Unfortunately new Howls do not use global howler data so they are manually set in playSingle/playDouble and must be manually updated here
        this.setState({soundOn: !this.state.soundOn}, () => {
            if (this.state.soundOn){
                Howler.volume(1);
                for (let i in Howler._howls){
                    Howler._howls[i].volume(1);
                }
            } else {
                Howler.volume(0);
                for (let i in Howler._howls){
                    Howler._howls[i].volume(0);
                }
            }
        })
    }

    playVideo = (video) => {
        this.setState({
            modalMode: video
        }, () => {
            if(!this.state.modalIsOpen){
                this.openModal()
            }   
        })
    }

    confirmBook = () =>{
        //Stop background music loop
        this.playSound.stopSound();
        //Stop the loop of reminder audio
        clearInterval(this.state.melodyReminderInterval);
        clearTimeout(this.state.melodyReminderInitial);
        //load assets
        this.openBook();
        //have modal play reading video
        this.playVideo('video-reading')
        // Set the initial background to the image of the ground
        this.changeBg(6);
    }

    closeBook = () =>{
        this.playVideo('video-end');
    }

    isIE = () => {
        if ((/*@cc_on!@*/false) || (document.documentMode)){
            return true;
        } else {
            return false;
        }
    }

    render() {
        let modalClass = 'modal__custom';
        let overlayClass = 'modal__overlay';
        if (this.state.modalMode === "video-intro" || this.state.modalMode === "video-reading" || this.state.modalMode === "video-end"){
            modalClass = 'modal__custom video__custom';
            overlayClass = 'modal__overlay video__overlay';
        }

        return (
            <>
            <HashRouter>
                <div className="game" id="game" aria-live="polite">
                    <Controls 
                        toggleSound={this.toggleSound}
                        soundOn={this.state.soundOn}
                        handleMenuClick={this.handleMenuClick}
                        handleReplayClick={this.handleReplayClick}  
                        backgroundIndex={this.state.backgroundIndex}
                        stopSound={this.playSound.stopSound}/>
                    <Switch>
                        <Route path="/" exact>
                            <TitleScreen
                                changeBg={this.changeBg}
                                playVideo={this.playVideo}/>
                        </Route>
                        <Route path="/find">
                            <SplashScreen
                                bookData={this.state.bookData}
                                openBook={this.openBook} 
                                handleBookClick={this.handleBookClick}
                                changeBg={this.changeBg}
                                playSound={this.playSound}/>
                        </Route>
                        <Route path="/book">
                            <InteractiveBookScreen 
                                setCmfDataLayer={this.setCmfDataLayer}
                                pageRef={ref => (this.pageRef = ref)}
                                pageImages={this.state.pageImages} 
                                book={this.state.bookData[this.state.selectedBook]} 
                                backgroundIndex={this.state.backgroundIndex}
                                changeBg={this.changeBg}                
                                bookUnloaded={this.onBookUnloaded}
                                playSound={this.playSound}
                                soundOn={this.state.soundOn}
                                soundIsPlaying={this.state.soundIsPlaying}
                                currentSoundType={this.state.currentSoundType}
                                talkingBear={this.state.talkingBear}
                                narrationOn={this.state.narrationOn}
                                closeBook={this.closeBook}/>
                        </Route>
                    </Switch>
                    <Captions
                        shown={this.state.captionBoxShown}
                        speaker={this.state.captionSpeaker}
                        text={this.state.captionText}/>
                    <Background backgroundImage={this.state.backgroundImages[this.state.backgroundIndex]}/>
                    <Modal
                        isOpen={this.state.modalIsOpen}
                        onRequestClose={this.closeModal}
                        shouldReturnFocusAfterClose={!this.isIE()}
                        contentLabel={this.state.modalMode}
                        className={modalClass}
                        overlayClassName={overlayClass}>
                        <ModalDefault
                            modalMode={this.state.modalMode} 
                            selectedBook={this.state.selectedBook} 
                            confirmBook={this.confirmBook} 
                            closeModal={this.closeModal}
                            playSound={this.playSound}
                            soundOn={this.state.soundOn}
                            narrationOn={this.state.narrationOn}
                            captionsOn={this.state.captionsOn}
                            handleNarrationClick={this.toggleNarration}
                            handleCaptionsClick={this.toggleCaptions}/>
                    </Modal>
                </div>
            </HashRouter>
            
            </>
        )
    }
}


export default Game;