import React from 'react';
import './NavigationBook.scss';
import {Link} from 'react-router-dom';
import btnVictory from "../../../Images/Icons/victory_btn.png";

class NavigationBook extends React.Component {

    handleClickBackCover = () => {
        this.props.closeBook();
    }

    render() {
        let leftArrowClass = 'btn-nav nav-left';
        let rightArrowClass = 'btn-nav nav-right';

        if (this.props.page === 0){
            leftArrowClass = 'no-visibility';
            rightArrowClass = 'btn-nav nav-right nav-animated';
        }

        if(!this.props.backCover){
            return (
                <div className='navigationBook' aria-live="polite">
                    <div className={"navButtons"}>
                        <button onClick={this.props.previousPage} id={'nav-left'} className={leftArrowClass} aria-label="Previous Page"/> 
                        <button onClick={this.props.nextPage} id={'nav-right'} className={rightArrowClass} aria-label="Next Page"/>
                    </div> 
                </div>
            )
        } else {
            return (
                <div className='navigationBook' aria-live="polite">
                    <div className={"navButtons"}>
                        <button onClick={this.props.previousPage} id={'nav-left'} className={"btn-nav nav-left"} aria-label="Previous Page"></button>
                        <Link to={'/find'} id={'nav-right'} onClick={this.handleClickBackCover} aria-label="Return home and play ending video in modal">
                            <img src={btnVictory} alt={"Home Icon with star and checkmark."} id={"victory-book-"+this.props.book.icon_id}/>
                        </Link>
                    </div> 
                </div>
            )
        }
    }
}

export default NavigationBook;