import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import React from 'react';
import ReactDOM from 'react-dom';
import './index.scss';
import './fonts.scss';
import App from './App';
import * as serviceWorker from './serviceWorker';

import TagManager from 'react-gtm-module'
 
const tagManagerArgs = {
    gtmId: 'GTM-WKCXJDD'
}
 
TagManager.initialize(tagManagerArgs)

const startApp = () => {
  ReactDOM.render(<App />, document.getElementById('root'));
  serviceWorker.register();
};

if(window.cordova) {
  document.addEventListener('deviceready', startApp, false);
} else {
  startApp();
}