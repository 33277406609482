import React from 'react';
import './Book.scss';
import Page from "../Page/Page";
import PawCover from '../PawCover/PawCover';

class Book extends React.Component {

    constructor(props) {
        super(props);

        this.state = {

            bookData: this.props.book,

            activeTimeouts: [],

            // Used to track karaoke words
            karaokeActiveWord: null,
            karaokeFinishedWords: [],

            // Used for accessibility, see togglePageDisplay
            displayPageComponent: true
        };

        // Early exit if book doesn't exist
        if (this.state.bookData === undefined) {
            window.location.href = "/"
            return;
        }
    }

    checkStart = () => {
        if (this.props.page === 0) {
            return true;
        } else {
            return false;
        }
    }

    checkBackCover = () => {
        let bookLength = this.state.bookData.pages.length - 1;
        if (this.props.page === bookLength) {
            return true;
        } else {
            return false;
        }
    }

    //The cover page is hidden behind the paw cover visually but needs to be hidden from screen readers as well
    //This reveals it when the user clicks the paw cover
    togglePageDisplay = () => {
        this.setState({displayPageComponent: true})
    }
    
    //This function is called in two places:
    //---By odd numbered (left side) pages when they mount
    //---By the replay narration function
    //It calls the playsound function in narration mode, which will automatically chain the sound file for the even # page
    playNarration = () => {
        let soundId = 'b'+this.props.book.icon_id+'_p'+this.props.page+'_narration';
        this.props.playSound('narrator', soundId);
    }

    //This is called only by the replay button
    replayNarration = () => {
        if(this.props.soundIsPlaying){
            //This check is currently disabled to let users interrupt active sounds by pressing buttons
            //return;
        }

        //Manual call of functions that would normally be called by each page when it mounts
        this.playNarration();
        this.karaoke(this.props.page);
        this.karaoke(this.props.page+1);
    }

    //This function used to be called at the page level but it was moved here so that it can be used by the repeat narration button
    //Each page calls this function when it mounts.
    //replayNarration calls this function for both shown pages when clicked.
    //See KARAOKE1.0 for main logic
    karaoke = (page) => {
        const setCurrentAnimations = () =>{
            const words = this.state.bookData.pages[page].words

            //The list of active timers is stored in state so that they can be deleted later
            //State should not be modified directly, so we clone the list, push the word to the clone, then setState to the clone
            const updateTimeouts = (newTimeout) => {
                let copy = this.state.activeTimeouts
                copy.push(newTimeout);
                this.setState({activeTimeouts: copy})
            }

            //Change the active word for the relevant page to the target word
            //Then use the data for that word to set a timeout which sets the finished status
            const setActive = (i) => {
                //console.log('setting active word. page: ', page);
                let word = words[i];
                this.setState({karaokeActiveWord: word.index});
                let timeToWordEnd = setTimeout(() => {setFinished(i)}, words[i].timeLength);
                updateTimeouts(timeToWordEnd);
            }

            //When the timeout expires for a specific word, add that word to the list of finished words in state
            //State should not be modified directly, so we clone the list, push the word to the clone, then setState to the clone
            //Then, if this was the last word on the page, make sure there is no active word 
            const setFinished = (i) => {
                let word = words[i];
                let finishedWords = []
                finishedWords = this.state.karaokeFinishedWords;
                finishedWords.push(word.index);
                //console.log('setting finished words. page: ', page);

                this.setState({karaokeFinishedWords: finishedWords});
                if (i + 1 >= words.length){
                    this.setState({karaokeActiveWord: null});
                }
            }

            //Each word has a time variable indicating when it should become active
            //This sets a timeout to execute the activation function at the time indicated for each word
            const animateWords = () =>{
                //console.log('animating words. page: ', page);
                for (let i in words){
                    let word = words[i];
                    let timetoWordStart = setTimeout(() => {setActive(i)}, word.timeStart);
                    updateTimeouts(timetoWordStart);
                }
            }

            animateWords();
        }

        //KARAOKE1.0
        //Each word on a page has timeouts to track when it becomes active and finished.
        //The word component bases its style on the list of active and finished words in this component
        //
        //Logic which occurs after this function is called by a page flip or the replay function:  

        //Clear previous data only for the first (odd) page of a set so that the second page does not cancel the first
        if(page % 2 !== 0){
            //First, access the list of timeouts for any previous animations (from other pages) and clear them
            this.clearPreviousAnimations();
            //Then, reset the list of active and finished words
            this.resetWordLists();
        }

        if (!this.state.bookData.pages[page].text){
            return;
        }
        
        //Create all new timeouts
        setCurrentAnimations();
    }

    resetWordLists = () => {
        this.setState ({
            karaokeActiveWord: null,
            karaokeFinishedWords: []
        });
    }

    clearPreviousAnimations = () =>{
        //console.log('clearing timeouts. page: ', this.props.page);
        let timeouts = this.state.activeTimeouts;
        for (let i in timeouts){
            clearTimeout(timeouts[i]);
        }
        this.setState({activeTimeouts: []});
    }

    //Keep track of any changes in the sound
    //When narration is playing and the sound type switches, deactivate the karaoke animation 
    componentWillReceiveProps(nextProps){
        if(nextProps.currentSoundType === 'narrator'){
            return;
        }
        if(this.props.currentSoundType !== nextProps.currentSoundType){
            this.clearPreviousAnimations();
            this.resetWordLists();
        }
    }

    //The cover page is visually hidden behind the paw cover component but needs to be hidden from screen readers as well
    componentDidMount(){
        if(this.props.page === 0 && this.props.backgroundIndex === 6){
            this.setState({displayPageComponent: false})
        }
    }

    render() {
        let page = this.props.page;

        if (this.checkStart()){
            return (
                <div className={`book`} aria-live="polite">
                    <div className={`book-single`}>
                        <div className={`bookContent`}>
                            <Page key={page}
                                displayPageComponent={this.state.displayPageComponent}
                                pageData={this.state.bookData.pages[page]}
                                image={this.props.pageImages[page]}
                                book={this.props.book.icon_id}
                                page={page}
                                playSound={this.props.playSound}
                                playNarration={this.playNarration}
                                karaoke={this.karaoke}
                            />
                            <PawCover
                                book={this.props.book.icon_id}
                                playSound={this.props.playSound}
                                togglePageDisplay={this.togglePageDisplay}
                                backgroundIndex={this.props.backgroundIndex}/>
                        </div>
                    </div>
                </div>
            );
        } else if (this.checkBackCover()){
            return (
                <div className={`book`} aria-live="polite">
                    <div className={`book-single`}>
                        <div className={`bookContent`}>
                            <Page key={page}
                                displayPageComponent={this.state.displayPageComponent}
                                pageData={this.state.bookData.pages[page]}
                                image={this.props.pageImages[page]}
                                book={this.props.book.icon_id}
                                page={page}
                                playSound={this.props.playSound}
                                playNarration={this.playNarration}
                                karaoke={this.karaoke}
                            />
                        </div>
                    </div>
                </div>
                
            );
        } else {
            return (
                <div className={`book`} aria-live="polite">
                    <div className={`book-double ` + this.state.bookData.styleName}>
                        <div className={`inner-spine upper`} aria-hidden="true"></div>
                        <div className={`inner-spine lower`} aria-hidden="true"></div>
                        <div className={`bookContent`}>
                            <Page key={page}
                                displayPageComponent={this.state.displayPageComponent}
                                pageData={this.state.bookData.pages[page]}
                                image={this.props.pageImages[page]}
                                book={this.props.book.icon_id}
                                page={page}
                                playSound={this.props.playSound}
                                playNarration={this.playNarration}
                                narrationOn={this.props.narrationOn}
                                karaoke={this.karaoke}
                                karaokeActiveWord={this.state.karaokeActiveWord}
                                karaokeFinishedWords={this.state.karaokeFinishedWords}
                            />
                            <div className={'inner-shadow'} aria-hidden="true"></div>
                            <Page key={page+1}
                                displayPageComponent={this.state.displayPageComponent}
                                pageData={this.state.bookData.pages[page+1]}
                                image={this.props.pageImages[page+1]}
                                book={this.props.book.icon_id}
                                page={page+1}
                                playSound={this.props.playSound}
                                playNarration={this.playNarration}
                                narrationOn={this.props.narrationOn}
                                karaoke={this.karaoke}
                                karaokeActiveWord={this.state.karaokeActiveWord}
                                karaokeFinishedWords={this.state.karaokeFinishedWords}
                            />
                        </div>
                    </div>
                </div>
            );
        }
    }
}

export default Book;