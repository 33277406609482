import React from 'react';
import './Page.scss';
import Hitbox from "../Page/Hitbox/Hitbox"
import Word from "./Word/Word"

class Page extends React.Component{

    constructor(props)
    {
        super(props);

        this.state = {
            clickables: [],
            hitboxesToRender: []
        };

        //this.playSound = this.props.playSound.bind(this);
    }

    importAll(r) {
        let assets = {};
        r.keys().map((item, index) => { assets[item.replace('./', '')] = r(item); });
        return assets;
    }

    componentDidMount(){
        //narration plays automatically when the page loads, unless the user has turned it off
        if (this.props.narrationOn === true){
            //The playSound function in Game.js plays narration for both pages so we only send the request for odd numbered pages
            if(this.props.page % 2 !== 0){
                this.props.playNarration();
            }
            this.props.karaoke(this.props.page);
        }
        this.loadClickables();
    }

    loadClickables(){
        let clickables;
        //arguments of require.context must be static se we use a switch statement instead of feeding in a variable path
        switch (this.props.book){
            case '0':
                clickables = this.importAll(require.context("../../Books/Interactions/Book0", false, /\.(png)$/));
                break;
            case '1':
                clickables = this.importAll(require.context("../../Books/Interactions/Book1", false, /\.(png)$/));
                break;
            case '2':
                clickables = this.importAll(require.context("../../Books/Interactions/Book2", false, /\.(png)$/));
                break;
            case '3':
                clickables = this.importAll(require.context("../../Books/Interactions/Book3", false, /\.(png)$/));
                break;    
        }
        this.setState({clickables: clickables});
    }

    mapOfWords(){
        if(this.props.karaokeActiveWord !== undefined && this.props.karaokeFinishedWords !== undefined){
            return <>
                <div className={'text-container'} aria-hidden="true">
                    {this.props.pageData.words.map((word, index) => 
                        <Word 
                            key={index} 
                            word={word}
                            active={this.props.karaokeActiveWord}
                            finished={this.props.karaokeFinishedWords}/>
                    )}
                </div>
            </>
        } else {
            return '';
        }
    }

    //This component loads the asset list of all clickable images for the appropriate book
    //Then, it creates a hitbox component for each clickable listed in the data for the page
    //Each hitbox is passed the image which matches its ID
    //Possible TODO: have this component only load images for the current page
    render() {
        return(
            <div className="page" aria-hidden={this.props.displayPageComponent === true ? 'false' : 'true'}>
                <img src={this.props.image} alt={this.props.pageData.altText}/>
                {this.mapOfWords()}
                <p className={'sr-only'}>{this.props.pageData.text}</p>
                {this.props.pageData.clickables.map((item) => (
                    <Hitbox
                        playSound={this.props.playSound} 
                        key={item.id}
                        image={this.state.clickables[item.id + '.png']}
                        imagePh={this.state.clickables[item.placeholderId + '.png']}
                        imageTf={this.state.clickables[item.thirdFrameId + '.png']}
                        data={item}/>
                    ))}
                <div className={"pageNumber " + ((this.props.page % 2) ? 'rightPageNumber' : 'leftPageNumber')}>{this.props.page}</div>
            </div>
        )
    }
}

export default Page;