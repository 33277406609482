import React from 'react';

import './Modal.scss';

import optionsOn from "../../Images/Icons/options_on.png";
import optionsOff from "../../Images/Icons/options_off.png";
import btnClose from '../../Images/Icons/close_btn.png';

class ModalOptions extends React.Component{
    handleCaptionsClick = () =>{
        this.props.handleCaptionsClick()
    }

    handleNarrationClick = () =>{
        this.props.handleNarrationClick()
    }

    render() {
        return(
            <div className={'modal modal__options'} aria-live="polite">
                <div className={'menu-content'}>
                    <h2>OPTIONS</h2>
                    <div className={'options-group'}>
                        <div className={'option-container'}>
                            <h3>Captions</h3>
                            <button className={"btn"} onClick={this.handleCaptionsClick} aria-label={this.props.captionsOn === true ? "Captions on/off toggle. Currently On." : "Captions on/off toggle. Currently Off."}>
                                <img src={this.props.captionsOn === true ? optionsOn : optionsOff} alt={this.props.captionsOn === true ? "Captions on/off icon. Currently On." : "Captions on/off icon. Currently Off."}/>
                            </button>
                        </div>
                        <div className={'option-container'}>
                            <h3>Read Aloud to Me</h3>
                            <button className={"btn"} onClick={this.handleNarrationClick} aria-label={this.props.narrationOn === true ? "Read Aloud Mode on/off toggle. Currently On." : "Read Aloud Mode on/off toggle. Currently Off."}>
                                <img src={this.props.narrationOn === true ? optionsOn : optionsOff} alt={this.props.narrationOn === true ? "Read Aloud Mode on/off icon. Currently On." : "Read Aloud Mode on/off icon. Currently Off."}/>
                            </button>
                        </div>
                    </div>
                </div>
                <button className={"btn-close"} onClick={this.props.closeModal} aria-label="Close">
                    <img src={btnClose} alt='Close icon'></img>
                </button>
            </div>
        )
        
    }
}

export default ModalOptions;
