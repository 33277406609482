import React from 'react';
import './Bear.scss';

class Bear extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            imgSrc: this.props.bear,
            timerStorage: [],
            bearIsDisplayed: true
        };
    }

    //Make the bears appear after the narrator has finished speaking
    componentDidMount () {

        //bears appear after narrator is done speaking (plus a slight delay)
        //this time matches TRIP and PURP
        let timeToAppear = 5500 + 500;

        //adjust time for other books (shorter and longer narration)
        if (this.props.book === '1'){
            timeToAppear = 4600 + 500;
        } else if (this.props.book === '2'){
            timeToAppear = 13500 + 500;
        }

        //If the narrator is not reading the text, appear right away
        if(!this.props.narrationOn){
            timeToAppear = 1000;
        }

        //Bears appear one by one from left to right. farther right bears get more delay
        if (this.props.name === 'melody') {
            timeToAppear += 300;
        } else if (this.props.name === 'scout') {
            timeToAppear += 600;
        } else if (this.props.name === 'boomer') {
            timeToAppear += 950;
        }

        if(this.props.page === 1){
            this.setState({bearIsDisplayed: false})

            //make each bear appear after the designated time
            const timer = setTimeout (()=>{
                this.setState({bearIsDisplayed: true});
                this.props.playSound('otherNonInterrupting', this.props.ding);
            }, timeToAppear)

            //store timer for future deletion
            const timers = [timer]

            //play the prompt to touch the bears after all dings are done
            if(this.props.name === 'boomer'){
                const timer2 = setTimeout (()=>{
                    this.props.playSound('touchPrompt');
                }, timeToAppear + 750)
                timers.push(timer2);
            }
            
            this.setState({timerStorage: timers})
        }
    }

    componentWillReceiveProps (nextProps) {
        //if the user changes pages:
        //delete the timers that will make the bear appear
        //make the bear appear
        if(nextProps.page !== 1){
            this.setState({bearIsDisplayed: true});
            this.cleanupTimers();
        }

        //Toggle outline on each bear when it starts/stops talking
        if(nextProps.talkingBear === this.props.name){
            this.setState({imgSrc: this.props.bearOutline});
        } else {
            this.setState({imgSrc: this.props.bear});
        }
    }

    componentWillUnmount (){
        this.cleanupTimers();
    }

    cleanupTimers = () => {
        for (let i in this.state.timerStorage){
            clearTimeout(this.state.timerStorage[i]);
        }
        this.setState({timerStorage: []})
    }

    handleClick = () => {
        let soundId = 'b'+this.props.book+'_p'+this.props.page+'_'+this.props.name;
        this.props.playSound('bear', soundId);
    }

    //Change the image source for the bear when hovered
    handleMouseOver = () => {
        //hover effect: outline bear
        if (window.screen.width < 1025){
            //hack to avoid hover state getting stuck on touch devices
            //TODO
            return;
        } else {
            this.setState({imgSrc: this.props.bearOutline});
        }
    }
    
    handleMouseOut = () => {
        //hover effect: remove outline from bear
        //but not if bear is outlined because it is talking
        if(this.props.name !== this.props.talkingBear){
            this.setState({imgSrc: this.props.bear});
        }
    }

    render() {
        let bearAria = "Play "+this.props.name+" reaction sound";
        let bearAlt = this.props.name+" the bear."; 

        let bearClass = "bear " + this.props.name;
        if(!this.state.bearIsDisplayed){
            bearClass += " transparent";
        }

        return (
            <button className={bearClass} onClick={this.handleClick} onMouseOver={this.handleMouseOver} onMouseOut={this.handleMouseOut} aria-label={bearAria}>
                <img className={"bearProfile"} src={this.state.imgSrc} alt={bearAlt}/>
            </button>
        )
    };
}

export default Bear;
