import './Word.scss';
import React from "react";

class Word extends React.Component {

    render() {
        let word = this.props.word;

        //This positions the word or phrase within the text container that covers the entire space of the page
        let wordPos = {
            top: word.yPos,
            left: word.xPos
        }

        //How long it takes for the overlay to go from 0 width to the width of the entire word
        let animationData = {
            animationDuration: word.timeLength + 'ms'
        }

        //Each phrase consists of two elements, the basic text which always appears, and the overlay.
        //The overlay sits exactly on top of the first text.
        let karaokeStyle = 'word';
        let overlayStyle = 'word word-overlay'
        if (this.props.active === word.index){
            //When a word becomes active, the overlay gets a new class with the "run-text" animation attached
            //This animation goes from 0% to 100% width over the time specified in animationData
            //Thus revealing a new text style from left to right
            //The basic text also gets a new style to change color although this is not necessary
            karaokeStyle = 'word karaoke-text'
            overlayStyle = 'word word-overlay karaoke-active-overlay'
        } else if (this.props.finished.includes(word.index) === true){
            //When this word is added to the list of finished words, apply new styles
            //Basic text goes back to its original state
            //The overlay has a style to indicate that it has been read 
            karaokeStyle = 'word'
            overlayStyle = 'word word-overlay karaoke-finished-overlay'
        }

        return (
            <div style={wordPos}>
                <span className={karaokeStyle} >{word.word} </span>
                <span className={overlayStyle} style={animationData}>{word.word} </span>
            </div>
        );
    }
}

export default Word;